header{
    height: 100vh;
    width: 100vw;
    /* padding-top: 7rem; */
    overflow: hidden;
}

video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.contents{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.header__container{
    /* text-align: center; */
    width: 100vw;
    height: 100%;
    /* height: 100%; */
    position: relative;
    
}
.cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}
.header__socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
    margin-left: 6rem;
}
.header__socials::after{
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

.me{
    /* background:linear-gradient(var(--color-primary),transparent); */
    width: 25rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 12.5rem);
    margin-top: 4rem;
    
    /* border-radius: 12rem 12rem 0 0; */
    overflow: hidden;
    padding: 1rem 1.5rem 1.5rem 1.5rem;
    position: absolute;
}
.me img{
    border-radius: 2rem 2rem;
}


/* .scroll__down{
    position: absolute;
    right: 2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 200;
    font-size: 0.9rem;

} */
@media screen and (max-width: 1024px){
header{
    height: 68vh;
}
}
@media screen and (max-width: 600px){
header{
    height: 100vh;
}
.header__socials{
    
            
            display:  flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
          left: 2rem;
        }
        
        .header__socials::after{
            display: none;
        }
    .me {
    width: 25rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 12.5rem);
    margin-top: 4rem;
    
    /* border-radius: 12rem 12rem 0 0; */
    overflow: hidden;
    padding: 1rem 1.5rem 1.5rem 1.5rem;
}
}